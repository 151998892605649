"use client";

import { ReactNode, createContext, useContext, useEffect, useState } from "react";

interface ThemeContextProps {
    isDarkMode: boolean;

    changeTheme: (isDarkMode: boolean) => void;
    toggleTheme: () => void;
}

interface ThemeProviderProps {
    children: ReactNode;
}

const ThemeContext = createContext<ThemeContextProps>(undefined!);

export const useTheme = (): ThemeContextProps => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('ThemeProvider has not been found in DOM tree.');
    }
    return context;
};

export default function ThemeProvider({ children }: ThemeProviderProps) {
    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

    function toggleTheme() {
        setIsDarkMode(!isDarkMode);
    }

    function changeTheme(isDarkMode: boolean) {
        setIsDarkMode(isDarkMode);
    }

    const value: ThemeContextProps = { isDarkMode, changeTheme, toggleTheme };

    return <ThemeContext.Provider value={value}>
        <div className={isDarkMode ? "dark" : ""}>
            {children}
        </div>
    </ThemeContext.Provider>
}