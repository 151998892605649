"use client";

import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { ReactNode } from "react";

interface NextAuthProviderProps {
  children: ReactNode;
  session: Session | null;
}

export default function NextAuthProvider({ children, session }: NextAuthProviderProps) {
  return <SessionProvider refetchOnWindowFocus={false} refetchInterval={30} session={session}>
    {children}
  </SessionProvider>;
}
